import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faPen, faCircleUser, faBoxOpen, faTrash, faGear, faUserTie, faBuildingUser } from '@fortawesome/free-solid-svg-icons';
import ModalNovoFranquiado from '../components/ModalNovoFranquiado'
import ModalAssociarProdutoFranquiado from '../components/ModalAssociarProdutoFranquiado'


const Franquiados = ({ user, loading }) => {
    const [produtosFranquia, setProdutosFranquia] = useState([]);
    const [temaFranquia, settemaFranquia] = useState({});
    const [franquiados, setfranquiados] = useState([]);
    const [franquiadoSelecionado, setfranquiadoSelecionado] = useState({});
    const [caracteristica, setCaracteristica] = useState([]);
    const [usuariosBimer, setUsuariosBimer] = useState([]);

    //const [base64Image1, setBase64Image1] = useState('');
    //const [base64Image2, setBase64Image2] = useState('');
    //const [base64Image3, setBase64Image3] = useState('');

    const [showModalNovaCaracteristica, setShowModalNovaCaracteristica] = useState(false);
    const [showModalAssociarProdutoFranquiado, setShowModalAssociarProdutoFranquiado] = useState(false);
    const [showModalAssociarProdutoUsuario, setShowModalAssociarProdutoUsuario] = useState(false);

    const handleShowModalCaracteristica = () => {
        setShowModalNovaCaracteristica(true);
    };

    const handleShowModalCaracteristicaEditar = () => {
        setfranquiadoSelecionado({});

        setShowModalNovaCaracteristica(true);
    };

    const handleCloseModalCaracteristica = () => {
        setShowModalNovaCaracteristica(false);
    };

    // useEffect(() => {
    //     console.log(franquiados);
    // }, [franquiados]);

    const handleSalvarUsuario = async (userSalvar) => {

        if ((userSalvar.NomeUsuario ?? '') == '' ||
        (userSalvar.Senha ?? '') == '' ||
        (userSalvar.idCaracteristica ?? '') == '' ||
        (userSalvar.Perfil ?? 0) == 0) {
            //setShowAlert(true);
            return;
        }

        userSalvar.NomeUsuario = userSalvar?.NomeUsuario?.trim();

        //console.log(userSalvar);
        var requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json'
            },
            body: JSON.stringify(userSalvar),
            redirect: 'follow'
        };

        //const response = await fetch('http://localhost:63299/franquia/PostFranquiados', requestOptions);
        const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/PostFranquiados', requestOptions);

        if (!response.ok) {
            throw new Error('Erro ao enviar o formulário ');
        }

        //console.log('Formulário enviado com sucesso!', response);

        setShowModalNovaCaracteristica(false);

        //window.location.reload();
    };

    const handleSalvarProdutoFranquiado = async (produtoFranquiadoSalvar) => {

        if ((produtoFranquiadoSalvar.IdProduto ?? '') == '') {
            //setShowAlert(true);
            return;
        }

        produtoFranquiadoSalvar.IdCaracteristica = franquiadoSelecionado.idCaracteristica;

        var requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json'
            },
            body: JSON.stringify(produtoFranquiadoSalvar),
            redirect: 'follow'
        };

        //const response = await fetch('http://localhost:63299/franquia/PostProdutosFranquia', requestOptions);
        const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/PostProdutosFranquia', requestOptions);

        if (!response.ok) {
            throw new Error('Erro ao enviar o formulário ');
        }

        setShowModalAssociarProdutoFranquiado(false);

        window.location.reload();
    };

    const handleSalvarProdutoUsuario = async (produtoUsuarioSalvar) => {

        if ((produtoUsuarioSalvar.IdProduto ?? '') == '') {
            //setShowAlert(true);
            return;
        }

        produtoUsuarioSalvar.IdCaracteristica = franquiadoSelecionado.idCaracteristica;

        var requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json'
            },
            body: JSON.stringify(produtoUsuarioSalvar),
            redirect: 'follow'
        };

        //const response = await fetch('http://localhost:63299/franquia/PostProdutosFranquia', requestOptions);
        const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/PostProdutosFranquia', requestOptions);

        if (!response.ok) {
            throw new Error('Erro ao enviar o formulário ');
        }

        setShowModalAssociarProdutoFranquiado(false);

        window.location.reload();
    };

    const handleExcluirProdutoFranquiado = async (produtoFranquiadoExcluir) => {

        if ((produtoFranquiadoExcluir.IdProduto ?? '') == '') {
            //setShowAlert(true);
            return;
        }

        //console.log(produtoFranquiadoExcluir);

        var requestOptions = {
            method: 'DELETE',
            headers: {
                "Content-Type": 'application/json'
            },
            body: JSON.stringify(produtoFranquiadoExcluir),
            redirect: 'follow'
        };

        //const response = await fetch('http://localhost:63299/franquia/DeleteProdutosFranquia', requestOptions);
        const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/DeleteProdutosFranquia', requestOptions);

        if (!response.ok) {
            throw new Error('Erro ao enviar o formulário ');
        }

        window.location.reload();
    };

    const handleShowModalCaracteristicaProd = () => {
        if ((franquiadoSelecionado.idCaracteristica ?? '') != '') {
            setShowModalAssociarProdutoFranquiado(true);
        }
    };

    const handleCloseModalAssociarProdutoFranquiado = () => {
        setShowModalAssociarProdutoFranquiado(false);
    };

    const handleCloseModalAssociarProdutoUsuario = () => {
        setShowModalAssociarProdutoUsuario(false);
    };

    const handleSelecionarFranquiado = async (franquiado) => {
        setfranquiadoSelecionado(franquiado);

        buscarProdutosFranquiado(franquiado.idCaracteristica);
        //buscarTemaFranquiado(franquiado.idCaracteristica);
    };

    const buscarProdutosFranquiado = async (idCaracteristica) => {
        try {
            //const response = await fetch('http://localhost:63299/franquia/GetProdutosFranquia?IdCaracteristica=' + idCaracteristica);
            const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/GetProdutosFranquia?IdCaracteristica=' + idCaracteristica);

            if (!response.ok) {
                throw new Error('Erro ao carregar produtos da franquia');
            }

            const produtoFranquia = await response.json();

            setProdutosFranquia(produtoFranquia);
        } catch (e) {
            console.error(e);
        }
    };

    const buscarTemaFranquiado = async (idCaracteristica) => {
        try {
            //const response = await fetch('http://localhost:63299/franquia/GetTema?idCaracteristica=' + idCaracteristica);
            const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/GetTema?idCaracteristica=' + idCaracteristica);

            if (!response.ok) {
                throw new Error('Erro ao carregar produtos da franquia');
            }

            const tema = await response.json();

            settemaFranquia(tema);
        } catch (e) {
            console.error(e);
        }
    };

    const salvarTemaFranquiado = async (temaFranquia) => {
        try {

            var requestOptions = {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json'
                },
                body: JSON.stringify(temaFranquia),
                redirect: 'follow'
            };

            //const response = await fetch('http://localhost:63299/franquia/PostTema', requestOptions);
            const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/PostTema', requestOptions);

            if (!response.ok) {
                throw new Error('Erro ao carregar produtos da franquia');
            }

        } catch (e) {
            console.error(e);
        }
    };

    const handleBlurInputValor = async (e, selecionado) => {
        const { name, value } = e.target;

        selecionado.Valor = value;

        //console.log("Valor alterado:", selecionado );

        var requestOptions = {
            method: 'PUT',
            headers: {
                "Content-Type": 'application/json'
            },
            body: JSON.stringify(selecionado),
            redirect: 'follow'
        };

        //const response = await fetch('http://localhost:63299/franquia/PutProdutosFranquia', requestOptions);
        const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/PutProdutosFranquia', requestOptions);

        if (!response.ok) {
            throw new Error('Erro ao enviar o formulário ');
        }

        //window.location.reload();

    };

    //const handleOpenDialog = async (img) => {
    //    const input = document.createElement('input');
    //    input.type = 'file';
    //    input.accept = 'image/*';
    //    input.onchange = (event) => converterImgFileembase64(event, img);
    //    input.click();
    //};

    //const converterImgFileembase64 = (event, img) => {
    //    const file = event.target.files[0];

    //    if (file && franquiadoSelecionado) {
    //        const reader = new FileReader();

    //        reader.onload = (e) => {
    //            const base64Data = e.target.result;
    //            switch (img) {
    //                case 1:
    //                    //settemaFranquia({ ...temaFranquia, [base64Image1]: base64Data });
    //                    temaFranquia = { base64Image1: base64Data };
    //                    break;
    //                case 2:
    //                    //settemaFranquia({ ...temaFranquia, [base64Image2]: base64Data });
    //                    temaFranquia.base64Image2 = base64Data;
    //                    break;
    //                case 3:
    //                    //settemaFranquia({ ...temaFranquia, [base64Image3]: base64Data });
    //                    temaFranquia.base64Image3 = base64Data;
    //                    break;
    //            }

    //            if (temaFranquia.idCaracteristica == null) {
    //                temaFranquia.idCaracteristica = franquiadoSelecionado.idCaracteristica;
    //            }

    //            settemaFranquia({ ...temaFranquia });

    //            salvarTemaFranquiado(temaFranquia);
    //        };

    //        reader.readAsDataURL(file);
    //    }
    //};

    useEffect(() => {
        const fetchFranquiados = async () => {
            try {
                //const response = await fetch('http://localhost:63299/franquia/GetFranquiados');
                const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/GetFranquiados');
                if (!response.ok) {
                    throw new Error('Erro ao carregar franquiados');
                }
                const data = await response.json();

                console.log(data);
                setfranquiados(data);
            } catch (error) {
                console.error('Erro ao carregar franquiados:', error);
            } finally {

            }
        };

        const fetchCaracteristicas = async () => {
            try {
                //const response = await fetch('http://localhost:63299/api/GetCaracteristica');
                const response = await fetch('https://api.erp.rcasistemas.com.br/api/GetCaracteristica');
                if (!response.ok) {
                    throw new Error('Erro ao carregar Caracteristica');
                }
                const data = await response.json();

                //console.log(data);
                setCaracteristica(data);
            } catch (error) {
                console.error('Erro ao carregar Caracteristica:', error);
            } finally {

            }
        };

        const fetchUsuariosBimer = async () => {
            try {
                //const response = await fetch('http://localhost:63299/api/getListaUsuario');
                const response = await fetch('https://api.erp.rcasistemas.com.br/api/getListaUsuario');

                if (!response.ok) {
                    throw new Error('Erro ao carregar Usuarios Bimer');
                }

                const data = await response.json();

                setUsuariosBimer(data);
            } catch (error) {
                console.error('Erro ao carregar Usuarios:', error);
            } finally {

            }
        };

        fetchFranquiados();
        fetchCaracteristicas();
        fetchUsuariosBimer();
    }, [user]);

    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <div className="card m-2 shadow-lg rounded" >
                        <div className="table-responsive rounded">
                            <div className="card-header text-white bg-dark">
                                <div className="d-flex justify-content-between">
                                    <div className="card-title ">
                                        <FontAwesomeIcon icon={faCircleUser} /> Franqueados
                                    </div>
                                    <div className="btn btn-toolbar btn-dark" onClick={handleShowModalCaracteristicaEditar}><FontAwesomeIcon icon={faCirclePlus} /></div>
                                </div>
                            </div>
                            <div className="card-body custom-scrollbar" style={{ height: '300px', overflow: 'auto' }}>
                                <div className="table-responsive rounded" >
                                    <table className="table table-hover" >
                                        <tbody>
                                            {franquiados.map((franquia, idx) => (
                                                <tr key={franquia.idCaracteristica + franquia.NomeUsuario} className={`${(franquia.idCaracteristica == franquiadoSelecionado.idCaracteristica && franquia.NomeUsuario == franquiadoSelecionado.NomeUsuario) ? 'table-success' : ''} ${(franquia.Perfil == 1) ? 'table-secondary' : ''} `} onClick={() => (handleSelecionarFranquiado(franquia))}>
                                                    <td className="text-truncate">
                                                        <div>
                                                            <a className={`btn btn-light`} onClick={handleShowModalCaracteristica} ><FontAwesomeIcon icon={faPen} /></a>
                                                        </div>
                                                    </td>
                                                    <td className="text-truncate">{franquia.NomeUsuario}</td>
                                                    <td className="text-truncate">{franquia.NmCaracteristica}</td>
                                                    <td className="text-truncate">{(() => {
                                                        switch (franquia.Perfil) {
                                                            case 1:
                                                                return <div><FontAwesomeIcon icon={faUserTie} /></div>;
                                                            case 2:
                                                                return <div><FontAwesomeIcon icon={faBuildingUser} /></div>;
                                                            case 3:
                                                                return 'Vendedor';
                                                            case 4:
                                                                return 'ADM Vendedor';
                                                            case 5:
                                                                return 'Revenda';
                                                            case 6:
                                                                return 'Indicação'
                                                        }
                                                    })()}</td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(franquiadoSelecionado.Perfil == 2)}
                <div className="col-12 col-md-6" >
                    <div className="card m-2 shadow-lg rounded" >
                        <div className="card-header text-white bg-dark">
                            <div className="d-flex justify-content-between">
                                <div className="card-title ">
                                    <FontAwesomeIcon icon={faBoxOpen} /> Produtos {(franquiadoSelecionado?.NmCaracteristica != null) ? ' - ' + franquiadoSelecionado?.NmCaracteristica ?? '' : ''}
                                </div>
                                <div className="btn btn-toolbar btn-dark" onClick={handleShowModalCaracteristicaProd}><FontAwesomeIcon icon={faCirclePlus} /></div>
                            </div>
                        </div>
                        <div className="card-body custom-scrollbar" style={{ height: '300px', overflow: 'auto' }}>
                            {(produtosFranquia.length > 0) ? <div className="table-responsive rounded" >
                                <table className="table">
                                    <tbody>
                                        {produtosFranquia.map((prodFranquia, idx) => (
                                            <tr key={prodFranquia.IdProdutoFranquia}>
                                                <td className="text-justify">
                                                    <span >{prodFranquia.DescricaoProduto}</span >
                                                </td>
                                                <td className="text-truncate">
                                                    <div className="input-group">
                                                        <span className="input-group-text" >R$</span>
                                                        <input type="number" className="form-control " id={'inputValor' + idx} name="Valor" defaultValue={prodFranquia.Valor} onBlur={(e) => handleBlurInputValor(e, prodFranquia)} />
                                                    </div>
                                                </td>
                                                <td className="text-truncate">
                                                    <div>
                                                        <a className={`btn btn-light`} onClick={() => handleExcluirProdutoFranquiado(prodFranquia)}><FontAwesomeIcon icon={faTrash} /></a>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                                : ''}
                        </div>
                    </div>
                </div>
                {(franquiadoSelecionado.Perfil > 1)
                    ?
                    <div className="col-12 col-md-6">
                        <div className="card m-2 shadow-lg rounded">
                            <div className="card-header text-white bg-dark">
                                <div className="d-flex justify-content-between">
                                    <div className="card-title text-lowercase text-capitalize">
                                        <FontAwesomeIcon icon={faGear} /> Produtos do usuario {(franquiadoSelecionado?.NmCaracteristica != null) ? franquiadoSelecionado?.NmCaracteristica ?? '' : ''}
                                    </div>
                                    <div className="btn btn-toolbar btn-dark" onClick={handleShowModalCaracteristicaProd}><FontAwesomeIcon icon={faCirclePlus} /></div>
                                    {/*<div className="btn btn-toolbar btn-dark" onClick={handleShowModalCaracteristicaProd}><FontAwesomeIcon icon={faCirclePlus} /></div>*/}
                                </div>
                            </div>
                            <div className="card-body custom-scrollbar" style={{ height: '300px', overflow: 'auto' }}>
                                <div className="row">
                                    <div className="col-12 col-md-6">

                                    </div>
                                    <div className="col-12 col-md-6">

                                    </div>
                                </div>


                                {/*<div className="row">*/}
                                {/*    <div className="col-2">*/}
                                {/*        <label htmlFor="inputCorSecundaria" className="form-label">Cor Secundaria</label>*/}
                                {/*        <input type="color" className="form-control " id="inputCorSecundaria" name="corsecundaria" style={{ "width": "50px" }} />*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                    : ''
                }
            </div>

            <ModalNovoFranquiado
                showModal={showModalNovaCaracteristica}
                handleCloseModal={handleCloseModalCaracteristica}
                Caracteristicas={caracteristica}
                handleSalvarUsuario={handleSalvarUsuario}
                usuarioSelecionado={franquiadoSelecionado}
                usuariosBimer={usuariosBimer}
            />

            <ModalAssociarProdutoFranquiado
                showModal={showModalAssociarProdutoFranquiado}
                handleCloseModal={handleCloseModalAssociarProdutoFranquiado}
                handleSalvarProdutoFranquiado={handleSalvarProdutoFranquiado}
            />

            <ModalAssociarProdutoFranquiado
                showModal={showModalAssociarProdutoUsuario}
                handleCloseModal={handleCloseModalAssociarProdutoUsuario}
                handleSalvarProdutoFranquiado={handleSalvarProdutoUsuario}
            />
        </div>
    );
};

export default Franquiados;
