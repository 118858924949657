import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHotel, faLocationDot, faAddressBook, faCheck, faXmark, faDoorClosed, faUser, faFileSignature, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import emailjs from "emailjs-com";


const ModalDetalheClienteIndicado = ({ showModal, handleCloseModal, clienteSelecionado }) => {
    const [formData, setFormData] = useState({});
    const [mostrarMotivo, setMostrarMotivo] = useState(false);
    const [motivo, setMotivo] = useState("");
    const serviceID = "service_f8wsurv";
    const templateID = "template_sbicm5u";
    const publicKey = "UIAvPxa9oLpNDzw1w";

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value.toUpperCase() });
    };

    const handleSalvar = async () => {

        let valorFormatado = formData.ValorImplantacao?.replace(",", ".").trim();
        let valorNumerico = parseFloat(valorFormatado);

        valorNumerico = isNaN(valorNumerico) ? 0 : parseFloat(valorNumerico.toFixed(2));

        if (valorNumerico <= 0) {
            alert("O campo 'Valor' deve ser preenchido com um valor maior que 0!");
            return;
        }

        if (!formData.Data) {
            alert("Por favor, selecione uma data antes de continuar.");
            return;
        }

        const payload = {
            idIndicacao: clienteSelecionado?.Cliente?.idIndicacao,
            idUsuarioFranquia: clienteSelecionado?.Cliente?.idUsuarioFranquia,
            idUsuarioFranquiaIndicado: clienteSelecionado?.Cliente?.idUsuarioFranquiaIndicado,
            idPessoa: clienteSelecionado?.Cliente?.idPessoa,
            Obs: clienteSelecionado?.Cliente?.obs || null,
            Status: 1,
            Valor: valorNumerico,
            DataIndicacao: clienteSelecionado?.Cliente?.DataIndicacao || formData.Data,
            DataPrevisao: formData.Data
        };

        const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/PostIndicacao', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            throw new Error('Erro ao enviar os dados.');
        }

        const data = await response.json();

        alert('Indicação atualizada com sucesso!');
        console.log('Resposta do servidor:', data);

        handleCloseModal(true);
        window.location.reload();
    }

    const handleMostrarMotivo = () => {
        setMostrarMotivo(true);
    };

    const handleCancelar = async () => {

        if (!motivo.trim()) {
            alert("Por favor, informe um motivo para o cancelamento.");
            return;
        }

        const payload = {
            idIndicacao: clienteSelecionado?.Cliente?.idIndicacao,
            idUsuarioFranquia: clienteSelecionado?.Cliente?.idUsuarioFranquia,
            idUsuarioFranquiaIndicado: clienteSelecionado?.Cliente?.idUsuarioFranquiaIndicado,
            idPessoa: clienteSelecionado?.Cliente?.idPessoa,
            Obs: `Não Contratado: ${motivo.trim()}`,
            Status: 2,
            DataIndicacao: clienteSelecionado?.Cliente?.DataIndicacao
        };

        const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/PostIndicacao', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            throw new Error('Erro ao enviar os dados.');
        }

        const data = await response.json();

        const formatarData = (data) => {
            return data ? new Date(data).toLocaleDateString('pt-BR') : 'Data não disponível';
        };

        const emailParams = {
            to_email: "lucio@rcasistemas.com.br",
            from_name: "Indicação RCA Sistemas",
            from_email: "noreply@rcasistemas.com.br",
            subject: "Indicação não contratada!",
            message: `
                Olá, Lúcio!

                Passando para informar que a indicação descrita abaixo não foi fechada.

                Empresa: ${clienteSelecionado?.Cliente?.cliente} - ${clienteSelecionado?.Cliente?.cnpj}

                Data da Indicação: ${formatarData(clienteSelecionado?.Cliente?.DataIndicacao)}

                Indicado: ${clienteSelecionado?.Cliente?.indicado}

                Vendedor: ${clienteSelecionado?.Cliente?.vendedor}

                Motivo: ${motivo}

                Atenciosamente,
                RCA Sistemas
            `,
        };

        emailjs.send(
            serviceID,
            templateID,
            emailParams,
            publicKey
        )
        
        alert('Indicação atualizada com sucesso!');

        handleCloseModal(true);
        window.location.reload();
    }

    return (
        <div>
            {showModal && (
                <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header text-white bg-dark">
                                <h5 className="modal-title"><FontAwesomeIcon icon={faUser} /> Cliente</h5>
                                <button type="button" className="btn-close btn-close-white" onClick={handleCloseModal}>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12 col-xl-6">
                                        <div className="card shadow mb-3">
                                            <div className="card-header text-white bg-dark">
                                                <FontAwesomeIcon icon={faHotel} /> Dados Empresa
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <label htmlFor="inputRazao" className="form-label">Razão Social</label>
                                                        <input type="text" className="form-control " disabled id="inputRazao" defaultValue={clienteSelecionado?.Cliente?.cliente?.trim()} />
                                                    </div>
                                                    <div className="col-12">
                                                        <label htmlFor="inputFantasia" className="form-label">Nome Fantasia</label>
                                                        <input type="text" className="form-control " disabled id="inputFantasia" defaultValue={clienteSelecionado?.Cliente?.fantasia} />
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <label htmlFor="inputCnpj" className="form-label">Cnpj</label>
                                                        <input type="text" className="form-control " disabled id="inputCnpj" defaultValue={clienteSelecionado?.Cliente?.cnpj} />
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <label htmlFor="inputCnpj" className="form-label">Status</label>
                                                        <input type="text" className="form-control " disabled id="inputStatus" defaultValue={clienteSelecionado?.Cliente?.status === 0 ? "Pendente" : clienteSelecionado?.Cliente?.status === 1 ? "Contratado" : clienteSelecionado?.Cliente?.status === 2 ? "Cancelado" : "Desconhecido"} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-xl-6">
                                        <div className="card shadow mb-3">
                                            <div className="card-header text-white bg-dark">
                                                <FontAwesomeIcon icon={faLocationDot} /> Endereço
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12 col-sm-12">
                                                        <label htmlFor="inputEndereco" className="form-label">Endereço</label>
                                                        <input type="text" className="form-control " disabled id="inputEndereco" defaultValue={clienteSelecionado?.Cliente?.endereco?.trim().toUpperCase()} />
                                                    </div>
                                                    <div className="col-5 col-sm-4">
                                                        <label htmlFor="inputBairro" className="form-label">Bairro</label>
                                                        <input type="text" className="form-control" disabled id="inputBairro" defaultValue={clienteSelecionado?.Cliente?.bairro?.trim()} />
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <label htmlFor="inputCidade" className="form-label">Cidade</label>
                                                        <input type="text" className="form-control " disabled id="inputCidade" defaultValue={clienteSelecionado?.Cliente?.cidade?.trim()} />
                                                    </div>
                                                    <div className="col-3 col-sm-2">
                                                        <label htmlFor="inputUf" className="form-label">Uf</label>
                                                        <input type="text" className="form-control" disabled id="inputUf" defaultValue={clienteSelecionado?.Cliente?.Uf?.trim()} />
                                                    </div>
                                                    <div className="col-12 col-sm-12">
                                                        <label htmlFor="inputEndereco" className="form-label">Observação</label>
                                                        <input type="text" className="form-control " disabled id="inputEndereco" defaultValue={clienteSelecionado?.Cliente?.obs?.trim().toUpperCase()} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-xl-6">
                                        <div className="card shadow mb-3">
                                            <div className="card-header text-white bg-dark">
                                                <FontAwesomeIcon icon={faAddressBook} /> Contato
                                            </div>
                                            <div className="card-body" style={{ height: '170px', overflow: 'auto' }}>
                                                <div className="table-responsive rounded" >
                                                    <table className="table">
                                                        <thead className="table-dark">
                                                            <tr>
                                                                <th scope="col">Tipo</th>
                                                                <th scope="col">Contato</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {clienteSelecionado?.Contato
                                                                .filter((contato) => contato.NmTipoContato !== "Email para boleto")
                                                                .map((contato) => (
                                                                    <tr key={(contato.IdPessoa ?? contato.DSContato) + contato.NmTipoContato}>
                                                                        <td className="text-truncate">{contato.NmTipoContato}</td>
                                                                        <td className="text-truncate">{contato.DSContato}</td>
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {clienteSelecionado?.Cliente?.status === 0 && (
                                        <div className="col-12 col-xl-6">
                                            <div className="card shadow mb-3">
                                                <div className="card-header text-white bg-dark">
                                                    <FontAwesomeIcon icon={faFileSignature} /> Contrato Firmado ?
                                                </div>

                                                <div className="card-body">
                                                    <div className="d-flex flex-column align-items-center">

                                                        <div className="d-flex justify-content-center w-100 gap-3">
                                                            <div className="col-auto text-center">
                                                                <div className="w-100">
                                                                    <label htmlFor="inputValorimplantacao" className="form-label fs-6">
                                                                        Honorários (R$)
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control text-center fs-7"
                                                                        id="inputValorimplantacao"
                                                                        value={formData.ValorImplantacao || "0,00"}
                                                                        onChange={(e) => {
                                                                            let value = e.target.value.replace(/[^0-9]/g, "");
                                                                            value = (parseFloat(value) / 100).toFixed(2);
                                                                            value = `${value.replace(".", ",")}`;
                                                                            handleChange({ target: { name: "ValorImplantacao", value } });
                                                                        }}
                                                                        name="ValorImplantacao"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-auto text-center">
                                                                <div className="w-100">
                                                                    <label htmlFor="inputData" className="form-label fs-6">
                                                                        Data Prevista
                                                                    </label>
                                                                    <input
                                                                        type="date"
                                                                        className="form-control text-center fs-7"
                                                                        id="inputData"
                                                                        value={formData.Data || ""}
                                                                        onChange={(e) => handleChange({ target: { name: "Data", value: e.target.value } })}
                                                                        name="Data"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="d-flex justify-content-center mt-4 w-100">
                                                            <button type="button" className="btn btn-danger me-2" onClick={handleMostrarMotivo}>
                                                                <FontAwesomeIcon icon={faXmark} /> Não Contratado
                                                            </button>
                                                            <button type="button" className="btn btn-success" onClick={handleSalvar}>
                                                                <FontAwesomeIcon icon={faCheck} /> Contratado
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                {mostrarMotivo && (
                                                    <div className="mt-3 me-2 ms-2">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <label htmlFor="inputMotivo" className="form-label ms-2">Motivo</label>
                                                            <FontAwesomeIcon icon={faXmark} className="text-danger me-1 mt-1" style={{ cursor: "pointer", fontSize: "1.0rem" }} onClick={() => { setMostrarMotivo(false); setMotivo(""); }} />
                                                        </div>
                                                        <textarea
                                                            id="inputMotivo"
                                                            className="form-control"
                                                            rows="3"
                                                            value={motivo}
                                                            onChange={(e) => setMotivo(e.target.value)}
                                                            placeholder="Descreva o motivo..."
                                                        ></textarea>

                                                        <div className="d-flex justify-content-center mt-3 mb-2">
                                                            <button type="button" className="btn btn-success" onClick={handleCancelar}>
                                                                <FontAwesomeIcon icon={faPaperPlane} /> Enviar
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                                    <FontAwesomeIcon icon={faDoorClosed} /> Fechar
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ModalDetalheClienteIndicado;