import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './style/CustomScrollbar.css';
import './style/Scrollbar.css';

import Clientes from './Pages/Clientes';
import NovoCliente from './Pages/NovoCliente';
import LoginForm from './Pages/LoginForm';
import Logout from './Pages/Logout';
import Autorizacao from './Pages/AutorizacaoClientes';
import Franquiados from './Pages/Franquiados';
import Produtos from './Pages/Produtos';
import WhiteLabel from './Pages/WhitelabelPage';

import Navbar from './components/Navbar';
import LoadingScreen from './components/LoadingScreen';

import '@popperjs/core';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import QuestionarioImplatancao from './Pages/QuestionarioImplatancao';
import NovaIndicacao from './Pages/NovaIndicacao';
import Indicacoes from './Pages/Indicacoes';

const App = () => {
    const [user, setUser] = useState(null);
    const [lastVisitedURL, setLastVisitedURL] = useState(null);
    const [loading, setLoading] = useState(false);

    const isAuthenticated = () => {
        const user = localStorage.getItem('user');
        return !!user;
    };

    useEffect(() => {
        const savedUser = localStorage.getItem('user');
        if (savedUser) {
            setUser(JSON.parse(savedUser));
        }
    }, []);

    useEffect(() => {
        if (user) {
            localStorage.setItem('lastVisitedURL', window.location.pathname);
            setLastVisitedURL(window.location.pathname);
        }

        //console.log(user);

    }, [user]);

    const handleLogin = (userData) => {
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));

        if (lastVisitedURL) {
            window.location.replace(lastVisitedURL);
        } else {
            window.location.replace('/'); // Redireciona para a página inicial se não houver URL anterior
        }
    };

    const handleLogout = () => {
        setUser(null);
        localStorage.removeItem('user');

        window.location.replace('/');
    };

    return (
        <>
            <BrowserRouter>
                {loading ? (
                    <LoadingScreen />
                ) : (
                    <div >
                        {(user) ? <Navbar user={user} /> : ''}

                        <div className="container-fluid w-100">
                            <Routes>
                                <Route path="/login" element={<LoginForm onLogin={handleLogin} />} />
                                <Route path="/logout" element={<Logout onLogout={handleLogout} />} />

                                <Route path="/" element={isAuthenticated() ? (user?.Perfil == 6 ? <Indicacoes user={user} /> : <Clientes user={user} />) : (<Navigate to="/login" />)}/>
                                <Route path="/indicacoes" element={isAuthenticated() ? <Indicacoes user={user} /> : <Navigate to="/login" />} />
                                <Route path="/novo" element={isAuthenticated() ? <NovoCliente user={user} /> : <Navigate to="/login" />} />
                                <Route path="/questionario" element={isAuthenticated() ? <QuestionarioImplatancao user={user} /> : <Navigate to="/login" />} />

                                <Route path="/autorizacao" element={isAuthenticated() ? <Autorizacao user={user} /> : <Navigate to="/login" />} />
                                <Route path="/franquiados" element={isAuthenticated() ? <Franquiados user={user} /> : <Navigate to="/login" />} />
                                <Route path="/produtos" element={isAuthenticated() ? <Produtos user={user} /> : <Navigate to="/login" />} />

                                <Route path="/whitelabel" element={isAuthenticated() ? <WhiteLabel user={user} /> : <Navigate to="/login" />} />
                                <Route path="/novaindicacao" element={isAuthenticated() ? <NovaIndicacao user={user} /> : <Navigate to="/login" />} />
                            </Routes>

                        </div>
                    </div>
                )};
            </BrowserRouter>
            <ToastContainer />
        </>
    );
};

export default App;
